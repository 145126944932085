<template>
	<div v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
			<v-tabs v-model="tab" left prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" show-arrows>
				<v-tooltip bottom v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-fountain-pen</v-icon>
						</v-tab>
					</template>
					<span>Saisir des notes</span>
				</v-tooltip>
				<v-tooltip bottom v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-check-circle-outline</v-icon>
						</v-tab>
					</template>
					<span>Voir les notes</span>
				</v-tooltip>
			</v-tabs>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)"
						class="mt-5">
						<EnterNotesTab :profile="profile" :user="user"></EnterNotesTab>
					</v-tab-item>
					<v-tab-item v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)"
						class="mt-5">
						<NotesListTab :profile="profile" :user="user"></NotesListTab>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import EnterNotesTab from '../components/Notes/EnterNotesTab.vue'
import NotesListTab from '../components/Notes/NotesListTab.vue'

export default {
	name: "Notes",
	props: ["user", "profile", "notifications", "config", "currentRoom"],
	components: {
		EnterNotesTab,
		NotesListTab,
	},
	data() {
		return {
			tab: 0,
		}
	},
}
</script>
