<template>
	<span>
        <v-select class="mb-5" :items="profiles" item-value="id" v-model="currentProfile" label="Utilisateur" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
            <template slot="item" slot-scope="data">
                <UserCard :profile="data.item"></UserCard>
            </template>
            <template slot="selection" slot-scope="data">
                <UserCard :profile="data.item"></UserCard>
            </template>
        </v-select>
        <span>
            <v-expansion-panels accordion>
                <v-expansion-panel v-for="note in notes" v-if="note.notes[currentProfile] && (note.visible || new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role))">
                    <v-expansion-panel-header hide-actions>
                        <v-row>
                            <v-col md="3" cols="6">
                                <strong class="light--text" v-text="note.name"></strong>
                                <i class="light--text" v-if="!note.visible">(Cachée)</i>
                                <strong class="light--text"> : </strong>
                            </v-col>
                            <v-col md="9" cols="6" class="d-flex align-center justify-right">
                                <v-progress-linear :value="note.notes[currentProfile].note/note.noteMax*100" height="15" :color="getColor(note.notes[currentProfile].note/note.noteMax*100)"></v-progress-linear>
                                <strong class="light--text pl-2" v-text="note.notes[currentProfile].note + '/' + note.noteMax"></strong>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12">
                                <div v-if="(note.notes[currentProfile].message && note.comUserVisible) || profile.role != 'User'">
                                    <strong class="light--text">Commentaire : </strong>
                                    <p class="px-8" v-text="note.notes[currentProfile].message"></p>
                                </div>
                                <div v-if="(note.comGlobal && note.comGlobalVisible) || profile.role != 'User'">
                                    <strong class="light--text">Commentaire global : </strong>
                                    <p class="px-8" v-text="note.comGlobal"></p>
                                </div>
                                <div v-if="((!note.notes[currentProfile].message || !note.comUserVisible) && (!note.comGlobal || !note.comGlobalVisible)) && profile.role == 'User'">
                                    <strong class="light--text">Pas de commentaire.</strong>
                                </div>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mt-5" disabled>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col md="3" cols="6">
                                <strong class="light--text">TOTAL</strong>
                                <i class="light--text" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)" >(Visible)</i>
                                <strong class="light--text"> : </strong>
                            </v-col>
                            <v-col md="9" cols="6" class="d-flex align-center justify-right">
                                <v-progress-linear :value="visible_total/visible_amount*100" height="15" :color="getColor(visible_total/visible_amount*100)"></v-progress-linear>
                                <strong class="light--text pl-2" v-text="visible_total + '/' + visible_amount"></strong>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                </v-expansion-panel>
                <v-expansion-panel v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)" disabled>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col md="3" cols="6">
                                <strong class="light--text">TOTAL</strong>
                                <i class="light--text">(Tous)</i>
                                <strong class="light--text"> : </strong>
                            </v-col>
                            <v-col md="9" cols="6" class="d-flex align-center justify-right">
                                <v-progress-linear :value="all_total/all_amount*100" height="15" :color="getColor(all_total/all_amount*100)"></v-progress-linear>
                                <strong class="light--text pl-2" v-text="all_total + '/' + all_amount"></strong>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                </v-expansion-panel>
            </v-expansion-panels>
            
        </span>
        <span v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
            <p class="mt-12">
                <v-row>
                    <v-col cols="12" md="6">
                        <div>
                            <strong class="light--text">Toutes les notes : </strong>
                        </div>
                        <strong class="light--text pr-5" v-text="'Min : ' + all_min"></strong>
                        <strong class="light--text pr-5" v-text="'Max : ' + all_max"></strong>
                        <strong class="light--text" v-text="'Moy : ' + all_avg + '%'"></strong>
                    </v-col>
                    <v-col cols="12" md="6" v-if="notes.filter(note => note.visible).length>0">
                        <div>
                            <strong class="light--text">Toutes les notes visibles : </strong>
                        </div>
                        <strong class="light--text pr-5" v-text="'Min : ' + visible_min"></strong>
                        <strong class="light--text pr-5" v-text="'Max : ' + visible_max"></strong>
                        <strong class="light--text" v-text="'Moy : ' + visible_avg + '%'"></strong>
                    </v-col>
                </v-row>
            </p>
        </span>
	</span>
</template>

<style scoped>
    * >>> .v-list-item,* >>> .v-select__selections{
        height:5rem !important;
    }
</style>

<script>
    import logger from "@/assets/functions/logger"

    import Profile from "@/classes/Profile"
    import Note from "@/classes/Note"

    import UserCard from "@/components/UserCard"

	export default {
		name: "NotesListTab",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
        components: {
            UserCard
        },
        data() {
            return {
                currentProfile: null,
                profiles : [],
                notes: [],
                unsub: [],
            }
        },
        created() {
            this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, (profiles) => {
				this.profiles = []
                let tmp_profiles = []
                profiles.forEach(profile => {
                    if(profile.role == "User"){
                        tmp_profiles.push(profile)
                    }
                })
                this.profiles = tmp_profiles

                if(new Array('Animator', 'Admin', 'SuperAdmin').includes(this.profile.role) && this.profiles.length > 0){
                    this.currentProfile = this.profiles[0].id;
                }
            }))

            this.unsub.push(
                Note.listenByRoom(this.profile.currentRoom, async (notes) => {
                    let tmp_notes = []
                    notes.forEach(note => {
                        if(note.stats.amount != this.profiles.length){
                            note.stats.amount = this.profiles.length
                            
                            let tmp_fixed_notes = {}

                            for(let profileId in this.profilesTable){
                                if(note.notes[profileId]){
                                    tmp_fixed_notes[profileId] = note.notes[profileId]
                                }else{
                                    tmp_fixed_notes[profileId] = {
                                        user: index,
                                        note: 0,
                                        message: "",
                                    }
                                }
                            }
                            note.notes = tmp_fixed_notes
                            note.save()
                        }
                        tmp_notes.push(note)
                    })
                    this.notes = tmp_notes
                })
            )

            if(!new Array('Animator', 'Admin', 'SuperAdmin').includes(this.profile.role)){
                this.currentProfile = this.profile.id;
            }
        },
        computed: {
            all_min : function(){
                let min = null
                let str = ""
                for(let note of this.notes){
                    if(note.notes[this.currentProfile] && (min == null || note.notes[this.currentProfile].note/note.noteMax < min)){
                        min = note.notes[this.currentProfile].note/note.noteMax
                        str = Math.round(parseFloat(note.notes[this.currentProfile].note)*100)/100 + '/' + note.noteMax
                    }
                }
                return str
            },
            all_max : function(){
                let max = null
                let str = ""
                for(let note of this.notes){
                    if(note.notes[this.currentProfile] && (max == null || note.notes[this.currentProfile].note/note.noteMax > max)){
                        max = note.notes[this.currentProfile].note/note.noteMax
                        str = Math.round(parseFloat(note.notes[this.currentProfile].note)*100)/100 + '/' + note.noteMax
                    }
                }
                return str
            },
            all_avg : function(){
                let sum = 0
                let counter = 0
                for(let note of this.notes){
                    if(note.notes[this.currentProfile]){
                        sum += parseFloat(note.notes[this.currentProfile].note)
                        counter += parseFloat(note.noteMax)
                    }
                }
                return Math.round((sum/counter)*100)
            },
            all_total : function(){
                let sum = 0
                for(let note of this.notes){
                    if(note.notes[this.currentProfile]){
                        sum += parseFloat(note.notes[this.currentProfile].note)
                    }
                }
                return Math.round(sum*100)/100
            },
            all_amount : function(){
                let sum = 0
                for(let note of this.notes){
                    if(note.notes[this.currentProfile]){
                        sum += parseFloat(note.noteMax)
                    }
                }
                return Math.round(sum*100)/100
            },
            visible_min : function(){
                let min = null
                let str = ""
                for(let note of this.notes){
                    if(note.visible && note.notes[this.currentProfile] && (min == null || note.notes[this.currentProfile].note/note.noteMax < min)){
                        min = note.notes[this.currentProfile].note/note.noteMax
                        str = Math.round(parseFloat(note.notes[this.currentProfile].note)*100)/100 + '/' + note.noteMax
                    }
                }
                return str
            },
            visible_max : function(){
                let max = null
                let str = ""
                for(let note of this.notes){
                    if(note.visible && note.notes[this.currentProfile] && (max == null || note.notes[this.currentProfile].note/note.noteMax > max)){
                        max = note.notes[this.currentProfile].note/note.noteMax
                        str = Math.round(parseFloat(note.notes[this.currentProfile].note)*100)/100 + '/' + note.noteMax
                    }
                }
                return str
            },
            visible_avg : function(){
                let sum = 0
                let counter = 0
                for(let note of this.notes){
                    if(note.visible && note.notes[this.currentProfile]){
                        sum += parseFloat(note.notes[this.currentProfile].note)
                        counter += parseFloat(note.noteMax)
                    }
                }
                return Math.round((sum/counter)*100)
            },
            visible_total : function(){
                let sum = 0
                for(let note of this.notes){
                    if(note.visible && note.notes[this.currentProfile]){
                        sum += parseFloat(note.notes[this.currentProfile].note)
                    }
                }
                return Math.round(sum*100)/100
            },
            visible_amount : function(){
                let sum = 0
                for(let note of this.notes){
                    if(note.visible && note.notes[this.currentProfile]){
                        sum += parseFloat(note.noteMax)
                    }
                }
                return Math.round(sum*100)/100
            },
        },
        methods: {
            getColor(value) {
                if(value < 25) {
                    return "error"
                } else if(value < 50) {
                    return "warning"
                } else if(value < 80) {
                    return "primary"
                } else {
                    return "success"
                }
            },
        },
        destroyed() {
            this.unsub.forEach((unsub) => {
                unsub()
            })
        },
	}
</script>
