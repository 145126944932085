<template>
    <span>
        <!-- note list -->
        <v-card elevation="2" class="my-3 px-3" v-for="(note, i) of notes" :key="i">
            <span class="d-flex justify-space-between align-center flex-wrap">
                <span class="d-flex justify-left align-center py-5">
                    <v-btn color="primary" icon @click="toggleVisibility(note)">
                        <v-icon v-if="note.visible">mdi-eye-outline</v-icon>
                        <v-icon v-else>mdi-eye-off-outline</v-icon>
                    </v-btn>
                    <b class="px-2" v-text="note.name"></b>
                </span>
                <span class="d-flex justify-left align-center flex-wrap gap-l">
                    <span class="d-flex flex-column align-end">
                        <span class="d-flex align-center gap-m">
                            <!-- min -->
                            <span class="d-flex align-center">
                                <v-icon>mdi-arrow-down</v-icon>
                                <span v-if="note.pointsConvertion">{{ Math.round((note.stats.min * note.pointsConvertion / note.noteMax) * 100) / 100 + '/' + note.pointsConvertion }}</span>
                                <span v-else>{{ Math.round(note.stats.min * 100) / 100 + '/' + note.noteMax }}</span>
                            </span>
                            <!-- max -->
                            <span class="d-flex align-center">
                                <v-icon>mdi-arrow-up</v-icon>
                                <span v-if="note.pointsConvertion">{{ Math.round((note.stats.max * note.pointsConvertion / note.noteMax) * 100) / 100 + '/' + note.pointsConvertion }}</span>
                                <span v-else>{{ Math.round(note.stats.max * 100) / 100 + '/' + note.noteMax }}</span>
                            </span>
                        </span>
                        <!-- avg -->
                        <span v-if="note.pointsConvertion">{{ 'Moy : ' + Math.round((note.stats.avg * note.pointsConvertion / note.noteMax) * 100) / 100 + '/' + note.pointsConvertion }}</span>
                        <span v-else>{{ 'Moy : ' + Math.round(note.stats.avg * 100) / 100 + '/' + note.noteMax }}</span>
                    </span>

                    <!-- actions -->
                    <span class="d-flex align-center">
                        <v-tooltip bottom v-if="profile.role != 'Corrector'">
                            <template #activator="tooltip">
                                <v-btn icon color="secondary" @click="openEditModal(note)" v-on="{ ...tooltip.on }">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Modifier</span>
                        </v-tooltip>
                        <!-- slideform -->
                        <v-tooltip bottom v-if="profile.role != 'Corrector' && note.form && note.slideFormNote">
                            <template #activator="tooltip">
                                <v-btn icon color="pink" @click="computeCorrection(note)" v-on="{ ...tooltip.on }">
                                    <v-icon>mdi-play-box-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Relancer la correction automatiqsue</span>
                        </v-tooltip>
                        <!-- robot -->
                        <v-tooltip bottom v-else-if="profile.role != 'Corrector' && note.form">
                            <template #activator="tooltip">
                                <v-btn icon color="info" @click="computeAutoNote(note)" v-on="{ ...tooltip.on }">
                                    <v-icon>mdi-robot-excited-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Relancer la correction automatiqsue</span>
                        </v-tooltip>
                        <!-- user's notes -->
                        <v-tooltip bottom v-if="profile.role != 'Corrector'">
                            <template #activator="tooltip">
                                <v-btn color="primary" icon @click="enteredNotes=note; comGlobal=note.comGlobal; enterModalDisplayed=true" v-on="{ ...tooltip.on }">
                                    <v-icon>mdi-bookmark-check-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Voir les notes</span>
                        </v-tooltip>
                        <!-- delete -->
                        <v-tooltip bottom v-if="profile.role != 'Corrector'">
                            <template #activator="tooltip">
                                <v-btn color="error" v-if="profile.role != 'Corrector'" icon @click="deleteNote(note)" v-on="{ ...tooltip.on }">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>
                    </span>
                </span>
            </span>
        </v-card>

        <!-- actions -->
        <span class="pt-3 d-flex justify-end gap-m">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                        <v-btn fab color="primary" v-if="profile.role != 'Corrector'" @click="creationModalDisplayed = true">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </span>
                </template>
                <span>
                    Créer une note.
                </span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                        <v-btn color="info" v-if="profile.role != 'Corrector'" fab @click="autoModalDisplayed = true">
                            <v-icon>mdi-robot-excited-outline</v-icon>
                        </v-btn>
                    </span>
                </template>
                <span>
                    Correction automatique.
                </span>
            </v-tooltip>

            <!-- slideform -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                        <v-btn color="pink" v-if="profile.role != 'Corrector'" fab @click="slideformModal = true">
                            <v-icon color="customWhite">mdi-play-box-outline</v-icon>
                        </v-btn>
                    </span>
                </template>
                <span>
                    Correction de formulaire v2.
                </span>
            </v-tooltip>
        </span>

        <v-dialog v-model="creationModalDisplayed" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Creer une note</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Nom" v-model="noteCreationName" required />
                                <v-text-field type="number" label="Note max" v-model="noteCreationMax"
                                    required />
                                <v-row class="d-flex align-center pt-3 pb-4 pl-3">
                                    <v-checkbox id="cboComGlobalVisible" class="d-none"
                                        v-model="comGlobalVisible"></v-checkbox>
                                    <label for="cboComGlobalVisible" class="pr-2" style="cursor: pointer">
                                        <v-icon color="primary" v-if="comGlobalVisible">mdi-eye-outline</v-icon>
                                        <v-icon color="primary" v-else>mdi-eye-off-outline</v-icon>
                                    </label>
                                    <span>Commentaire global</span>
                                </v-row>
                                <v-row class="d-flex align-center pl-3">
                                    <v-checkbox id="cboComUserVisible" class="d-none" v-model="comUserVisible"></v-checkbox>
                                    <label for="cboComUserVisible" class="pr-2" style="cursor: pointer">
                                        <v-icon color="primary" v-if="comUserVisible">mdi-eye-outline</v-icon>
                                        <v-icon color="primary" v-else>mdi-eye-off-outline</v-icon>
                                    </label>
                                    <span>Commentaire personnel</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="noteCreationName = ''; creationModalDisplayed = false">
                        Annuler </v-btn>
                    <v-btn color="blue darken-1" text @click="createNote"> sauvegarder </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="autoModalDisplayed" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Charger un formulaire</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select :items="mailForm" item-text="subject" item-value="id" label="Mail du formulaire"
                                    v-model="autoNoteMail"></v-select>
                                <v-text-field type="number" label="Note max" v-model="autoNoteMax" required />
                                <v-row class="d-flex align-center pt-3 pb-4 pl-3">
                                    <v-checkbox id="cboComGlobalVisible" class="d-none"
                                        v-model="comGlobalVisible"></v-checkbox>
                                    <label for="cboComGlobalVisible" class="pr-2" style="cursor: pointer">
                                        <v-icon color="primary" v-if="comGlobalVisible">mdi-eye-outline</v-icon>
                                        <v-icon color="primary" v-else>mdi-eye-off-outline</v-icon>
                                    </label>
                                    <span>Commentaire global</span>
                                </v-row>
                                <v-row class="d-flex align-center pl-3">
                                    <v-checkbox id="cboComUserVisible" class="d-none" v-model="comUserVisible"></v-checkbox>
                                    <label for="cboComUserVisible" class="pr-2" style="cursor: pointer">
                                        <v-icon color="primary" v-if="comUserVisible">mdi-eye-outline</v-icon>
                                        <v-icon color="primary" v-else>mdi-eye-off-outline</v-icon>
                                    </label>
                                    <span>Commentaire personnel</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="autoNoteMail = ''; autoModalDisplayed = false"> Annuler
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="createAutoNote"> sauvegarder </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editModalDisplayed" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Modifier une note</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Nom" v-model="noteEditName" required />
                                <v-text-field type="number" label="Note max" v-model="noteEditMax" required v-if="!hideNoteMax" />
                                <v-row class="d-flex align-center pa-3">
                                    <v-checkbox id="cboComGlobalVisible" class="d-none"
                                        v-model="comGlobalVisible"></v-checkbox>
                                    <label for="cboComGlobalVisible" class="pr-2" style="cursor: pointer">
                                        <v-icon color="primary" v-if="comGlobalVisible">mdi-eye-outline</v-icon>
                                        <v-icon color="primary" v-else>mdi-eye-off-outline</v-icon>
                                    </label>
                                    <span>Commentaire global</span>
                                </v-row>
                                <v-row class="d-flex align-center pl-3">
                                    <v-checkbox id="cboComUserVisible" class="d-none" v-model="comUserVisible"></v-checkbox>
                                    <label for="cboComUserVisible" class="pr-2" style="cursor: pointer">
                                        <v-icon color="primary" v-if="comUserVisible">mdi-eye-outline</v-icon>
                                        <v-icon color="primary" v-else>mdi-eye-off-outline</v-icon>
                                    </label>
                                    <span>Commentaire personnel</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text
                        @click="noteEditName = ''; noteEditeId = null; editModalDisplayed = false">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="editNote"> sauvegarder </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="enterModalDisplayed" max-width="1080px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Commentaire global</span>
                </v-card-title>
                <v-card-text>
                    <v-textarea rows="2" v-model="comGlobal" label="Message pour tous" counter maxlength="512" full-width
                        single-line></v-textarea>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Saisir des notes</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col md="6" cols="12" v-for="(note, i) of enteredNotes.notes" :key="i">
                                <v-card>
                                    <v-card-text>
                                        <span class="d-flex align-center">
                                            <UserCard :profile="profilesTable[note.user]"></UserCard>
                                            <v-spacer></v-spacer>
                                            <div class="d-flex flex-column align-end">
                                                <v-text-field type="number" v-model="note.note"
                                                    :suffix="'/' + enteredNotes.noteMax" label="Note"
                                                    style="max-width:100px" hide-details
                                                />
                                                <span v-if="enteredNotes.pointsConvertion" class="mt-1 text-caption">
                                                    {{ Math.round((note.note * enteredNotes.pointsConvertion / enteredNotes.noteMax) * 100) / 100 + '/' + enteredNotes.pointsConvertion }}
                                                </span>
                                            </div>
                                        </span>
                                        <span>
                                            <v-textarea rows="2" v-model="note.message" label="Message" counter
                                                maxlength="512" full-width single-line
                                            />
                                        </span>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text
                        @click="enteredNotes = {}; comGlobal = ''; enterModalDisplayed = false">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="saveEnteredNotes">sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- slideform -->
        <v-dialog v-model="slideformModal" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Charger un formulaire</span>
                </v-card-title>
                <v-card-text>
                    <v-select v-model="slideform"
                        :items="slideforms"
                        item-text="name"
                        item-value="id"
                        label="Formulaire"
                        hide-details class="pa-0"
                    />
                    <v-checkbox v-model="comUserVisible"
                        on-icon="mdi-eye-outline"
                        off-icon="mdi-eye-off-outline"
                        label="Commentaire personnel"
                        hide-details class="pa-0"
                    />
                    <v-checkbox v-model="comGlobalVisible"
                        on-icon="mdi-eye-outline"
                        off-icon="mdi-eye-off-outline"
                        label="Commentaire global"
                        hide-details class="pa-0"
                    />
                </v-card-text>
                <v-card-actions class="justify-end" v-if="loadingMessage === ''">
                    <v-btn color="blue darken-1" text @click="slideformModal = false">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="createSlideform">sauvegarder</v-btn>
                </v-card-actions>
                <v-card-actions class="justify-end" v-else>
                    <Loader :message="loadingMessage" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import logger from "@/assets/functions/logger"

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Profile from "@/classes/Profile"
import Mail from "@/classes/Mail"
import MailResponse from "@/classes/MailResponse"
import Note from "@/classes/Note"
import SlideForm from '@/classes/SlideForm'
import FormResponse from "@/classes/FormResponse"

import UserCard from "@/components/UserCard"
import Loader from '@/components/Loader'

import Correction from '@/assets/functions/corrections'

export default {
    name: "EnterNotesTab",
    props: ["user", "profile", "notifications", "config", "currentRoom"],
    components: {
        UserCard,
        Loader
    },
    data() {
        return {
            creationModalDisplayed: false,
            noteCreationName: "",
            noteCreationMax: 0,
            autoModalDisplayed: false,
            autoNoteMail: "",
            autoNoteMax: 0,
            editModalDisplayed: false,
            noteEditName: "",
            noteEditMax: 0,
            noteEditeId: null,
            enterModalDisplayed: false,
            enteredNotes: {},
            profilesTable: {},
            mailForm: [],
            notes: [],
            unsub: [],
            comGlobalVisible: false,
            comUserVisible: true,
            comGlobal: "",
            // slideform
            hideNoteMax: false,
            slideformModal: false,
            slideforms: [],
            slideform: null,
            loadingMessage: ''
        }
    },
    created() {
        this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, (profiles) => {
            this.profilesTable = {}
            let tmp_profiles = {}
            profiles.forEach(profile => {
                if (profile.role == "User") {
                    tmp_profiles[profile.id] = profile
                }
            })
            this.profilesTable = tmp_profiles
        }))

        this.unsub.push(
            Mail.listenOnlyFormByRoom(this.profile.currentRoom, async (mails) => {
                this.mailForm = mails
            })
        )

        // notes
        this.unsub.push(
            Note.listenByRoom(this.profile.currentRoom, async notes => {
                this.notes = notes
                await Promise.all(this.notes.map(async note => {
                    await this.sameAmoutOfUserAndNote(note)
                }))
            })
        )

        // slideforms
        this.unsub.push(SlideForm.listenByRoom(this.profile.currentRoom, forms => {
            this.slideforms = forms
        }))
    },
    computed: {
        totalUserAmount: function () {
            return Object.keys(this.profilesTable).length
        },
    },
    methods: {
        openEditModal(note) {
            if (note.slideFormNote) this.hideNoteMax = true
            else this.hideNoteMax = false

            this.noteEditName = note.name
            this.noteEditMax = note.noteMax
            this.noteEditeId = note.id
            this.comGlobalVisible = note.comGlobalVisible
            this.comUserVisible = note.comUserVisible
            this.editModalDisplayed = true
        },

        createAutoNote() {
            let currentMail = null
            for (let mail of this.mailForm) {
                if (mail.id == this.autoNoteMail) {
                    currentMail = mail
                    break
                }
            }
            if (currentMail) {
                let tmp_notes = {}

                for (let index in this.profilesTable) {
                    tmp_notes[index] = {
                        user: index,
                        note: 0,
                        message: "",
                    }
                }

                let tmp_stats = {
                    amount: this.totalUserAmount,
                    min: 0,
                    max: 0,
                    avg: 0,
                }

                let note = new Note(null, this.profile.currentRoom, currentMail.subject, this.autoNoteMax, tmp_notes, tmp_stats, false, this.comGlobalVisible, this.comUserVisible, '', currentMail.id)
                note.save()
                    .then(() => {
                        logger.log(this.profile.id, "AUTO NOTE ADDED", this.profile.email + " a ajouté la note automatique " + note.name)
                    })

                this.autoModalDisplayed = false
                this.autoNoteMail = ""
                this.autoNoteMax = 0
                this.comGlobalVisible = false
                this.comUserVisible = true
            }
        },
        computeAutoNote(note) {
            let tmp_notes = {}
            MailResponse.getByMail(note.form)
                .then(mailResponses => {
                    mailResponses.forEach(mailResponse => {
                        let current_note = 0
                        let current_max = 0

                        for (let question of mailResponse.form.questions) {
                            //if (question.correct && new Array('bool', 'number', 'dependency', 'text', 'fillTheBlank','singleChoice', 'multipleChoice', 'list', 'orderTheList', 'classification').includes(question.type)) {
                            if (question.correct && question.type != 'free') {
                                current_note += 1
                            }
                            current_max += 1
                        }

                        tmp_notes[mailResponse.sender] = {
                            user: mailResponse.sender,
                            note: Math.round(note.noteMax * (current_note / current_max) * 100) / 100,
                            message: "",
                        }
                    })

                    for (let index in this.profilesTable) {
                        if (tmp_notes[index] == undefined) {
                            tmp_notes[index] = {
                                user: index,
                                note: 0,
                                message: "Non rendu",
                            }
                        }
                    }

                    let min = null
                    let max = null
                    let sum = 0
                    let avg = 0

                    for (let profileId in tmp_notes) {
                        if (min || min > parseInt(tmp_notes[profileId].note)) {
                            min = parseInt(tmp_notes[profileId].note)
                        }
                        if (!max || max < parseInt(tmp_notes[profileId].note)) {
                            max = parseInt(tmp_notes[profileId].note)
                        }
                        sum += parseInt(tmp_notes[profileId].note)
                    }

                    if (min != null && max != null) {
                        avg = sum / Object.keys(tmp_notes).length
                    } else {
                        min = 0
                        max = 0
                        avg = 0
                    }

                    note.notes = tmp_notes
                    note.stats = {
                        amount: Object.keys(tmp_notes).length,
                        min: min,
                        max: max,
                        avg: avg,
                    }

                    note.save()
                        .then(() => {
                            logger.log(this.profile.id, "AUTO NOTE", this.profile.email + " a lancé une correction automatique pour " + note.id)
                        })
                })
        },

        createNote() {
            let tmp_notes = {}
            for (let index in this.profilesTable) {
                tmp_notes[index] = {
                    user: index,
                    note: 0,
                    message: "",
                }
            }

            let tmp_stats = {
                amount: this.totalUserAmount,
                min: 0,
                max: 0,
                avg: 0,
            }

            let note = new Note(null, this.profile.currentRoom, this.noteCreationName, this.noteCreationMax, tmp_notes, tmp_stats, false, this.comGlobalVisible, true)
            note.save()
                .then(() => {
                    logger.log(this.profile.id, "NOTE ADDED", this.profile.email + " a ajouté la note " + this.noteCreationName)
                })

            this.creationModalDisplayed = false
            this.noteCreationName = ""
            this.noteCreationMax = 0
            this.comGlobalVisible = false
            this.comUserVisible = true
        },

        toggleVisibility(note) {
            note.visible = !note.visible
            note.save()
                .then(() => {
                    logger.log(this.profile.id, "NOTE EDITED", this.profile.email + " a modifié la note " + note.name)
                })
        },
        editNote() {
            Note.updateById(this.noteEditeId, {
                name: this.noteEditName,
                noteMax: this.noteEditMax,
                comGlobalVisible: this.comGlobalVisible,
                comUserVisible: this.comUserVisible
            })
                .then(() => {
                    logger.log(this.profile.id, "NOTE EDITED", this.profile.email + " a modifié la note " + this.noteEditName)
                })
            this.editModalDisplayed = false
            this.noteEditName = ""
            this.noteEditMax = 0
            this.comGlobalVisible = false
            this.comUserVisible = true
        },
        saveEnteredNotes() {
            let min = null
            let max = null
            let sum = 0
            let avg = 0

            for (let profileId in this.enteredNotes.notes) {
                if (!min || min > parseInt(this.enteredNotes.notes[profileId].note * 100) / 100) {
                    min = parseInt(this.enteredNotes.notes[profileId].note * 100) / 100
                }
                if (!max || max < parseInt(this.enteredNotes.notes[profileId].note * 100) / 100) {
                    max = parseInt(this.enteredNotes.notes[profileId].note * 100) / 100
                }
                sum += parseInt(this.enteredNotes.notes[profileId].note * 100) / 100
            }

            if (min != null && max != null) {
                avg = sum / Object.keys(this.enteredNotes.notes).length
            } else {
                min = 0
                max = 0
                avg = 0
            }
            Note.updateById(this.enteredNotes.id, {
                notes: this.enteredNotes.notes,
                stats: {
                    amount: this.enteredNotes.stats.amount,
                    min: min,
                    max: max,
                    avg: avg,
                },
                comGlobal: this.comGlobal
            })
                .then(() => {
                    logger.log(this.profile.id, "NOTE ENTERES", this.profile.email + " a entré des notes pour " + this.enteredNotes.id)
                })
            this.enterModalDisplayed = false
            this.enteredNotes = {}
        },
        deleteNote(note) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    logger.log(this.profile.id, "NOTE DELETED", this.profile.email + " a supprimé la note " + note.name)
                    note.delete()
                }
            })
        },

        // slideform
        async createSlideform() {
            if (this.slideform === null) {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Formulaire invalide.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
                return
            }

            const form = this.slideforms.find(x=>x.id===this.slideform)
            const new_note = new Note(
                null,
                this.profile.currentRoom,
                form.name,
                0,
                [],
                { amount: 0, avg: null, max: null, min: null },
                false,
                this.comGlobalVisible,
                this.comUserVisible,
                '',
                form.id,
                form.pointsConvertion,
                true
            )

            await this.computeCorrection(new_note)
            this.slideformModal = false
        },
        async computeCorrection(note) {
            const form = this.slideforms.find(x=>x.id===note.form)

            this.loadingMessage = 'Récupération des réponses'
            const responses = await FormResponse.getByForm(note.form)

            this.loadingMessage = 'traitement des réponses'
            note.noteMax = this.getNoteMax(form.slides)
            note.notes = {}
            note.stats = { amount: 0, avg: null, max: null, min: null }
            for (const response of responses) {
                // userNote
                const userNote = {
                    user: response.sender,
                    message: '',
                    note: 0
                }
                form.slides.forEach((slide, index) => {
                    if (slide.layout === 'question') {
                        if (slide.content.type === 'simple') {
                            slide.content.questions.forEach((question, key) => {
                                if (!['free', 'rating'].includes(question.type)) {
                                    if (response.responses[index]) { // 0 point if no response
                                        let isCorrect = false
                                        if (question.type === 'dependent') {
                                            isCorrect = Correction(question.type, response.responses[index][key], question.goodResponse, slide.content.questions, response.responses)
                                        } else {
                                            isCorrect = Correction(question.type, response.responses[index][key], question.goodResponse)
                                        }
                                        if (typeof isCorrect === 'boolean')
                                            userNote.note += isCorrect === true ? parseFloat(question.points) : 0
                                        else if (Array.isArray(isCorrect))
                                            userNote.note += isCorrect.every(x=>x!==false) ? parseFloat(question.points) : 0
                                    }
                                }
                            })
                        }
                        if (slide.content.type === 'advanced') {
                            let isCorrect = false
                            if (![null, undefined].includes(response.responses[index])) {
                                isCorrect = Correction(slide.content.question.type, response.responses[index], slide.content.question.goodResponse)
                                if (slide.content.question.type === 'classification') {
                                    isCorrect = isCorrect.items.every(x => x.every(y => y === true)) && isCorrect.cat.every(x => x === true)
                                } else if (slide.content.question.type === 'linkingLists') {
                                    isCorrect = isCorrect.every(x => x === true) && slide.content.question.goodResponse.length === Object.values(response.responses[index]).length
                                }
                            }
                            userNote.note += isCorrect === true ? parseFloat(slide.content.question.points) : 0
                        }
                    }
                })
                note.notes[userNote.user] = userNote

                //stats
                this.updateStats(note, userNote.note)
            }

            await this.sameAmoutOfUserAndNote(note)

            this.loadingMessage = 'Sauvegarde des notes.'
            await note.save()
            this.loadingMessage = ''
        },
        getNoteMax(slides) {
            let noteMax = 0
            for (const slide of slides) {
                if (slide.layout === 'question') {
                    if (slide.content.type === 'advanced') noteMax += parseFloat(slide.content.question.points)
                    if (slide.content.type === 'simple') {
                        for (const question of slide.content.questions) {
                            if (!['free', 'rating'].includes(question.type)) {
                                noteMax += parseFloat(question.points)
                            }
                        }
                    }
                }
            }
            return noteMax
        },
        updateStats(note, userNote) {
            note.stats.amount++
            if (note.stats.avg === null) {
                note.stats.avg = userNote
                note.stats.min = userNote
                note.stats.max = userNote
            } else {
                if (userNote < note.stats.min) note.stats.min = userNote
                if (userNote > note.stats.max) note.stats.max = userNote
                note.stats.avg = ( note.stats.avg*(note.stats.amount-1) + userNote ) / note.stats.amount
            }
        },
        async sameAmoutOfUserAndNote(note) {
            let isUpdated = false
            const users = await Profile.getByRoomAndByRole(this.profile.currentRoom, 'User')
            for (const user of users) {
                if (note.notes[user.id] === undefined) {
                    isUpdated = true
                    note.notes[user.id] = {
                        user: user.id,
                        note: 0,
                        message: "Non rendu.",
                    }
                    this.updateStats(note, 0)
                }
            }
            if (isUpdated === true && note.id) note.save()
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => {
            if (typeof unsub === 'function')
                unsub()
        })
    },
}
</script>
